<template>
  <div>
    <b-row v-if="searchSubjectsList.length > 0">
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(subject, index) in subjectsActivePage"
        :key="index"
      >
        <b-card img-top class="mb-3 mx-auto cardShadow" no-body>
          <b-card-img
            :src="subject.imagePath == null ? '/media/placeholder/default_image.png' : subject.imagePath"
            class="w-100"
            style="border-radius: 0 !important"
          ></b-card-img>
          <b-button-group>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
            >
              <unicon name="ticket" class="pr-1" fill="#5E5873" width="18"></unicon>
              <strong>({{ subject.subscriptionsCount }})</strong>
            </b-button>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
            >
              <unicon name="notebooks" class="pr-1" fill="#5E5873" width="18"></unicon>
              <strong>({{ subject.unitsCount }})</strong>
            </b-button>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
            >
              <unicon name="bookmark" class="pr-1" fill="#5E5873" width="18"></unicon>
              <strong>({{ subject.lessonsCount }})</strong>
            </b-button>
          </b-button-group>
          <b-card-header class="py-1">
            <strong>{{ subject.name }}</strong>
          </b-card-header>
          <b-card-body class="pt-0">
            <b-card-text class="text-truncate">
              <template v-for="(teacher, index) in subject.teachersNames">
                <template v-if="index + 1 != subject.teachersNames.length">
                  {{ teacher }} -
                </template>
                <template v-else>
                  {{ teacher }}
                </template>
              </template>
              <span v-if="subject.teachersNames.length == 0">لايوجد أساتذة للمادة</span>
            </b-card-text>
          </b-card-body>

          <b-button
            href="#"
            size="md"
            variant="primary"
            style="border-radius: 0 0 0.428rem 0.428rem !important"
            :to="`/subjects-units/subjects/details/${subject.id}`"
          >
            عرض التفاصيل
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <b-pagination
          :value="subjectsFirstPage"
          :total-rows="searchSubjectsList.length"
          :per-page="subjectPageLength"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => subjectsPagination(value)"
        >
          <template #prev-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
          <template #next-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row v-else class="justify-content-center mt-2">
      <h4>لا يوجد مواد متاحة</h4>
    </b-row>
    <subjectDialog />
  </div>
</template>
<style scoped>
.cardShadow {
  max-width: 20rem;
}
</style>
<script>
import store from "@/store";
import subjectDialog from "./create-subject";
import {
  BPagination,
  BCard,
  BCardText,
  BButtonGroup,
  BButton,
  BCardImg,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    subjectDialog,
    BPagination,
    BCard,
    BCardText,
    BButtonGroup,
    BButton,
    BCardImg,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
  },
  data: () => ({
  }),
  mounted() {
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: true,
      text: "مادة جديدة",
      fetchingFunction: this.toggleSubjectDialog,
      placeHolder: "ابحث عن مادة محددة",
      value: "",
      filterFunc: this.filterSubjects,
    });
    this.fetchSubjects();
  },
  computed: {
    ...mapGetters([
      "subjects",
      "subjectPageLength",
      "subjectsFirstPage",
      "subjectsActivePage",
      "searchSubjectsList"
    ]),
  },
  methods: {
    ...mapActions(["fetchSubjects", "toggleSubjectDialog", "subjectsPagination", "filterSubjects"]),
  },
};
</script>
