<template>
  <div>
    <validation-observer ref="createSubject">
      <b-form>
        <b-modal
          dialog-class="subject-modal-dialog"
          content-class="subject-modal-content"
          v-model="subjectModal"
          scrollable
          size="sm"
          ref="createModal"
          @hide="toggleSubjectDialog(false)"
        >
          <template #modal-title>
            <strong>إضافة مادة</strong>
          </template>
          <b-form-group label="اسم المادة" label-for="subjectName">
            <validation-provider #default="{ errors }" name="اسم المادة" rules="required">
              <b-form-input
                id="subjectName"
                v-model="subjectForm.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="غلاف المادة" label-for="subjectImage">
            <fileDragDrop
              @inValidFile="inValidSubjectImage"
              @uploaded="uploadSubjectImage"
              id="subjectImage"
              height="100px"
              type="image"
              title="أدرج الصورة هنا أو انقر للتحميل"
            />
             <img
              v-if="!!subjectForm.imagePath || subjectForm.imageDto"
              :src="
                !subjectForm.imageDto && !subjectForm.imageDto.base64
                  ? '/media/placeholder/default_image.png'
                  : subjectForm.imageDto.base64
              "
              style="max-height: 320px; object-fit: fill"
              class="w-100 my-2"
            />
          </b-form-group>
          <template #modal-footer>
            <b-button
              size="md"
              type="submit"
              variant="primary"
              @click.prevent="SubmitAddSubject()"
            >
              إضافة
            </b-button>
            <b-button
              @click="toggleSubjectDialog(false)"
              size="md"
              variant="outline-primary"
            >
              تراجع
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<style lang="scss">
.subject-modal-dialog {
  margin: 0 !important;
  max-height: 100vh !important;
  height: 100vh;
}
.subject-modal-content {
  max-height: 100vh !important;
  border-radius: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { BButton, BFormGroup, BFormInput, BForm } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import { required } from "@validations";
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationObserver,
    ValidationProvider,
    fileDragDrop,
  },
  data: () => ({
    subjectModal: false,
    required,
  }),
  created() {
    localize("ar");
  },
  computed: {
    ...mapGetters(["isSubjectDialog", "subjectForm"]),
  },
  methods: {
    ...mapActions(["toggleSubjectDialog", "setSubject"]),
    SubmitAddSubject() {
      this.$refs.createSubject.validate().then((success) => {
        if (success) {
          this.setSubject(this.subjectForm);
          this.$refs.createModal.hide();
          this.$bvToast.toast(`تم إضافة المادة ${this.subjectForm.name} بنجاح`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
    uploadSubjectImage(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
         this.subjectForm.imageDto = {
          base64: reader.result,
          contentType: file.type,
          fileName: file.name,
          length: file.size,
         }
      }).bind(this);
    },
    inValidSubjectImage() {
      console.log("invalid file type");
    },
  },
  watch: {
    isSubjectDialog(newVal) {
      this.subjectModal = newVal;
    },
  },
};
</script>
